import { environment } from "@environment/environment";

export enum RoleActions {
	CAN_ACCESS_CUSTOMER_DASHBOARD = 6,
	ALLOWS_SWITCH_USER = 27,
	ALLOW_SEE_CALLS_FROM_OTHERS_USERS = 29,
	ALLOW_REGISTER_USERS = 19,
	USER_ADMIN = 10,
	WHATSAPP_TEMPLATE_ACCESS = 39,

	WHATSAPP_TEMPLATE_MENU = 100,
	CONFIGURATION_ACCESS = 100000,
	WHATSAPP_TEMPLATE_SCREEN = 100001,
	REOPEN_FINALIZED_CONVERSATION = 1000000,
}

export enum MessageType {
	INPUT,
	OUTPUT,
}

export enum MessageStatus {
	PENDING_BROKER,
	PENDING_WHATSAPP,
	SENT,
	DELIVERED,
	READ,
	NOT_SENT = 99,
}

export enum ConversationStatus {
	WAITING,
	OPEN,
	FINISHED,
	OPENED_BY_BOT,
	OPERATED_BY_BOT,
}

export const ON_GOING_CALLS_ID = 0;

export const ConversationStatusLabel = new Map<number, string>([
	[ConversationStatus.WAITING, "Aguardando"],
	[ConversationStatus.OPEN, "Aberto"],
	[ConversationStatus.FINISHED, "Finalizado"],
	[ConversationStatus.OPENED_BY_BOT, "Aberto pelo bot"],
	[ConversationStatus.OPERATED_BY_BOT, "Bot"],
]);

export const ConversationTypeLabel = new Map<number, string>([
	[MessageType.INPUT, "Cliente Aguardando"],
	[MessageType.OUTPUT, "Aguardando Cliente"],
]);

export function colorByIndex(index: number) {
	const colors = [
		"#00bcd4",
		"#009688",
		"#4caf50",
		"#8bc34a",
		"#cddc39",
		"#ffeb3b",
		"#ffc107",
		"#ff9800",
		"#ff5722",
		"#795548",
		"#9e9e9e",
		"#607d8b",
		"#f44336",
		"#e91e63",
		"#9c27b0",
		"#673ab7",
		"#3f51b5",
		"#2196f3",
		"#03a9f4",
	];

	return colors[index] || "#009485";
}

export enum DaysOfWeek {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
}

export const DaysOfWeekLabel = new Map<number, string>([
	[DaysOfWeek.Monday, "Segunda"],
	[DaysOfWeek.Tuesday, "Terça"],
	[DaysOfWeek.Wednesday, "Quarta"],
	[DaysOfWeek.Thursday, "Quinta"],
	[DaysOfWeek.Friday, "Sexta"],
	[DaysOfWeek.Saturday, "Sábado"],
	[DaysOfWeek.Sunday, "Domingo"],
]);

export const IMAGE_EXTENSIONS = [
	"jpg",
	"jpeg",
	"jfif",
	"gif",
	"png",
	"bmp",
	"svg",
	"webp",
	"avif",
];
export const VIDEO_EXTENSIONS = [
	"mp4",
	"webm",
	"mov",
	"avi",
	"wmv",
	"flv",
	"mkv",
];
export const AUDIO_EXTENSIONS = [
	"mp3",
	"wav",
	"ogg",
	"opus",
	"wma",
	"acc",
	"aac",
	"flac",
	"m4a",
	"m4b",
];

export function statusColor(status: number) {
	const statusMap = new Map([
		[0, "border-blue-400"],
		[1, "border-green-400"],
		[2, "border-red-400"],
	]);

	return statusMap.get(status);
}

export const MESSAGE_STATUS_UNREAD = 1;

export const DEFAULT_MORNING_START_TIME = { hour: 8, minute: 0, second: 0 };
export const DEFAULT_MORNING_END_TIME = { hour: 12, minute: 0, second: 0 };
export const DEFAULT_AFTERNOON_START_TIME = { hour: 13, minute: 0, second: 0 };
export const DEFAULT_AFTERNOON_END_TIME = { hour: 18, minute: 0, second: 0 };
export const TIME_BEGINNING = "0001-01-01T";
export const TIME_END = ":00Z";

export enum LogType {
	QUEUE_ENTRY = 1,
	SERVICE_CHANNEL_CHANGE = 2,
	ATTACH_USER = 4,
	ATTACH_USER_BY_ADMIN = 5,
	RETURN_CONVERSATION_TO_USER = 6,
	CONVERSATION_FINISHED = 7,
	CONVERSATION_REOPENED = 8,
}

export const LogTypeLabel = new Map<number, string>([
	[LogType.QUEUE_ENTRY, "Entrou na fila"],
	[LogType.SERVICE_CHANNEL_CHANGE, "Trocou de canal"],
	[LogType.ATTACH_USER, "Atendente atribuído"],
	[LogType.ATTACH_USER_BY_ADMIN, "Atendente atribuído por outro usuário"],
	[LogType.CONVERSATION_FINISHED, "Atendimento finalizado"],
	[LogType.CONVERSATION_REOPENED, "Atendimento reaberto"],
]);

export const NOTIFICATION_TITLE = "Nova mensagem de ";

export const CDN_URL = environment.CDN_URL;

export enum FlowType {
	NORMAL_FLOW,
	CUSTOM_FLOW,
}

export enum IntegrationType {
	WHATSAPP_OFFICIAL = 1,
}

export enum LeadOrigin {
	WHATSAPP,
	MY_HONDA,
	WHATSAPP_META_ADS,
	TEIA_CRM,
	CAMPAIGN,
}

export enum LastMessageFrom {
	CLIENT,
	USER,
}

export const LastMessageFromLabel = {
	client: "Cliente",
	operador: "Operador",
};

export enum ReleaseNoteContentType {
	RESOURCES = 0,
	UPDATES = 1,
	BUGFIX = 2,
	PROBLEMS = 3,
	DEPRECIATION = 4,
}

export const releaseNoteContentTypeMap = new Map([
	[ReleaseNoteContentType.RESOURCES, "Novos recursos"],
	[ReleaseNoteContentType.UPDATES, "Melhorias"],
	[ReleaseNoteContentType.BUGFIX, "Correção de bugs"],
	[ReleaseNoteContentType.PROBLEMS, "Problemas conhecidos"],
	[ReleaseNoteContentType.DEPRECIATION, "Depreciação"],
]);

export function getLabelByOrigin(origin: number | undefined) {
	if (origin === undefined) {
		return "";
	}

	const labelMap = new Map([
		[LeadOrigin.WHATSAPP, "Whatsapp"],
		[LeadOrigin.MY_HONDA, "My Honda"],
		[LeadOrigin.WHATSAPP_META_ADS, "Whatsapp Meta Ads"],
		[LeadOrigin.TEIA_CRM, "TeiaCrm"],
		[LeadOrigin.CAMPAIGN, "Campanha"],
	]);

	return labelMap.get(origin) || "";
}

export function getFlagByOrigin(origin: number | undefined) {
	switch (origin) {
		case LeadOrigin.WHATSAPP:
			return "../../../assets/images/brands/whatsapp-logo.png";
		case LeadOrigin.MY_HONDA:
			return "../../../assets/images/brands/myhonda-logo.png";
		case LeadOrigin.WHATSAPP_META_ADS:
			return "../../../assets/images/brands/meta-logo.png";
		case LeadOrigin.TEIA_CRM:
			return "../../../assets/images/brands/teiacrm-logo.png";
		case LeadOrigin.CAMPAIGN:
			return "../../../assets/images/brands/campaign-logo.png";
		default:
			return "../../../assets/images/brands/unknown-logo.png";
	}
}

export const CHAT_MODAL_CLOSED = "chatModalClosed";
export const REMOVE_CONVERSATION = "removeConversation";
export const OPEN_RELEASE_NOTE = "openReleaseNote";

export const SETUP_NEED_TO_BE_DONE =
	"Um número de atendimento precisa ser configurado!";
export const NO_RELEASE_MESSAGE =
	"Não há novidades para exibição nesse momento!";

export const NOT_ASSIGNED = "Não atribuido";

export enum CampaignStatusEnum {
	DRAFT = 1,
	SCHEDULED,
	RUNNING,
	FINISHED,
	CANCELLED,
	ERROR,
}

export const campaignStatusLabel = new Map<number, string>([
	[CampaignStatusEnum.DRAFT, "Rascunho"],
	[CampaignStatusEnum.SCHEDULED, "Agendado"],
	[CampaignStatusEnum.RUNNING, "Executando"],
	[CampaignStatusEnum.FINISHED, "Finalizado"],
	[CampaignStatusEnum.CANCELLED, "Cancelado"],
	[CampaignStatusEnum.ERROR, "Erro"],
]);

export enum CampaignTargetStatusEnum {
	WAITING = 0,
	RUNNING,
	SENT,
	ERROR,
}

export const campaignTargetStatusLabel = new Map<number, string>([
	[CampaignTargetStatusEnum.WAITING, "Aguardando"],
	[CampaignTargetStatusEnum.RUNNING, "Executando"],
	[CampaignTargetStatusEnum.SENT, "Enviado"],
	[CampaignTargetStatusEnum.ERROR, "Erro"],
]);

export enum CampaignTypeEnum {
	EMAIL = 1,
	WHATSAPP_MESSAGE,
	WHATSAPP_TEMPLATE,
	FACEBOOK_MESSAGE,
	SMS,
}

export const campaignTypeLabel = new Map<number, string>([
	[CampaignTypeEnum.EMAIL, "Email"],
	[CampaignTypeEnum.WHATSAPP_MESSAGE, "Mensagem do WhatsApp"],
	[CampaignTypeEnum.WHATSAPP_TEMPLATE, "Template do WhatsApp"],
	[CampaignTypeEnum.FACEBOOK_MESSAGE, "Mensagem do Facebook"],
	[CampaignTypeEnum.SMS, "SMS"],
]);

export enum WhatsAppTemplateCategoryEnum {
	MARKETING = 1,
	UTILITY = 2,
}

export const WhatsAppTemplateCategoryLabel = new Map<number, string>([
	[WhatsAppTemplateCategoryEnum.MARKETING, "Marketing"],
	[WhatsAppTemplateCategoryEnum.UTILITY, "Utilidade"],
]);

export enum WhatsAppTemplateStatusEnum {
	Pending = 0,
	Approved = 1,
	Rejected = 2,
}

export const WhatsAppTemplateStatusLabel = new Map<number, string>([
	[WhatsAppTemplateStatusEnum.Pending, "Pendente"],
	[WhatsAppTemplateStatusEnum.Approved, "Aprovado"],
	[WhatsAppTemplateStatusEnum.Rejected, "Recusado"],
]);

export const LeadOriginLabel = new Map<number, string>([
	[LeadOrigin.WHATSAPP, "Whatsapp"],
	[LeadOrigin.MY_HONDA, "My Honda"],
	[LeadOrigin.WHATSAPP_META_ADS, "Whatsapp Meta Ads"],
	[LeadOrigin.TEIA_CRM, "TeiaCrm"],
	[LeadOrigin.CAMPAIGN, "Campanha"],
]);

export enum TemplateVariableComponentEnum {
	HEADER = 1,
	BODY = 2,
}

export const WhatsAppTemplateVariableComponentLabel = new Map<number, string>([
	[TemplateVariableComponentEnum.HEADER, "Cabeçalho"],
	[TemplateVariableComponentEnum.BODY, "Mensagem"],
]);

export enum TemplateVariableTypeEnum {
	CUSTOMER_NAME = 1,
	OTHERS = 2,
}

export const WhatsAppTemplateVariableTypeLabel = new Map<number, string>([
	[TemplateVariableTypeEnum.CUSTOMER_NAME, "Nome do cliente"],
	[TemplateVariableTypeEnum.OTHERS, "Outros"],
]);

export const MESSAGE_TOOLTIP_CONTENT = `Você pode adicionar campos váriaveis na Mensagem inserindo {{n}}, sendo 'n' o número de campos que deseja, sequencialmente. ex.: "Olá {{1}}, seu consórcio {{2}} foi contemplado."`;
export const HEADER_TOOLTIP_CONTENT = `Você pode adicionar um campo váriavel ao cabeçalho inserindo {{1}} para personalizar a mensagem ex.:"Olá {{1}}, temos uma oferta para você!"`;
